<template>
	<div class="hello" >

		<!-- <dv-loading>Loading...</dv-loading> -->


		<dv-full-screen-container class="content">

			<div class="top">
				<div class="left">{{nowTime}}</div>
				<div class="center">宁波市江北区城镇燃气安全检查公共服务平台</div>
				<div class="right" @click="toManager">管理员</div>
			</div>

			<div class="info">
				<div class="row">
					<dv-border-box-9 class="border">全区商/住户安全检查统计</dv-border-box-9>
				</div>
				<div style="flex:2" class="center">
					<div class="title">累计安全日</div>
					<div class="day">{{plateformData.totalTimeNum}}天</div>
				</div>
				<div class="row">
					<dv-border-box-9 class="border">全区燃气企业安全检查统计</dv-border-box-9>
				</div>
			</div>

			<div class="body" v-if="chartConfig1.data">

				<div class="left">
					<dv-border-box-11 class="border" :title="'商/住总户数:'+plateformData.householdTotalNum+'户'">
						<div class="content1">
							<div style="margin-top: 10px;">
								<div style="color:#FFF">瓶装液化气:{{plateformData.bottledTotalNum}}户</div>
								<div class="chart">
									<div class="left">
										<dv-active-ring-chart :config="chartConfig1" style="width:100px;height:100px" />
									</div>
									<div class="right">
										<div class="item">居民:{{plateformData.bottledResidentNum}}</div>
										<div class="item">非居民:{{plateformData.bottledNonResidentNum}}</div>
									</div>
								</div>
							</div>

							<div style="margin-top: 10px;">
								<div style="color:#FFF">管道天然气:{{plateformData.conduitTotalNum}}户</div>
								<div class="chart">
									<div class="left">
										<dv-active-ring-chart :config="chartConfig2" style="width:100px;height:100px" />
									</div>
									<div class="right">
										<div class="item">居民:{{plateformData.conduitResidentNum}}</div>
										<div class="item">非居民:{{plateformData.conduitNonResidentNum}}</div>
									</div>
								</div>
							</div>
						</div>
					</dv-border-box-11>
					<dv-border-box-11 class="border" :title="'截止当今已检查:'+plateformData.checkedHouseholdTotalNum+'户'">
						<div class="content1">
							<div style="margin-top: 10px;">
								<div style="color:#FFF">瓶装液化气:{{plateformData.checkedBottledTotalNum}}户</div>
								<div class="chart">
									<div class="left">
										<dv-active-ring-chart :config="chartConfig3" style="width:100px;height:100px" />
									</div>
									<div class="right">
										<div class="item">居民:{{plateformData.checkedBottledResidentNum}}</div>
										<div class="item">非居民:{{plateformData.checkedBottledNonResidentNum}}</div>
									</div>
								</div>
							</div>

							<div style="margin-top: 10px;">
								<div style="color:#FFF">管道天然气{{plateformData.checkedConduitTotalNum}}户</div>
								<div class="chart">
									<div class="left">
										<dv-active-ring-chart :config="chartConfig4" style="width:100px;height:100px" />
									</div>
									<div class="right">
										<div class="item">居民:{{plateformData.checkedConduitResidentNum}}</div>
										<div class="item">非居民:{{plateformData.checkedConduitNonResidentNum}}</div>
									</div>
								</div>
							</div>
						</div>
					</dv-border-box-11>
				</div>

				<div class="center">
					<img :src="area" class="area" alt="" ismap usemap="#area">

					<map name="area" id="area">
						<!-- 设置热区为圆。coords 的参数分别为水平轴、垂直轴、半径 -->
						<area shape="circle" coords="237,202,50" href="http://xlmmap.yunchiwangluo.com?street=慈城&lng=121.45494&lat=29.987272" /> <!-- 慈城镇 -->
						<area shape="circle" coords="351,301,50" href="http://xlmmap.yunchiwangluo.com?street=前江&lng=121.477281&lat=29.950451" /> <!-- 前江街道 -->
						<area shape="circle" coords="457,282,50" href="http://xlmmap.yunchiwangluo.com?street=洪塘&lng=121.508416&lat=29.943898" /> <!-- 前江街道 -->
						<area shape="circle" coords="560,295,50" href="http://xlmmap.yunchiwangluo.com?street=庄桥&lng=121.553752&lat=29.928937" /> <!-- 前江街道 -->
						<area shape="circle" coords="650,343,50" href="http://xlmmap.yunchiwangluo.com?street=甬江&lng=121.601344&lat=29.926222" /> <!-- 前江街道 -->
						<area shape="circle" coords="548,373,20" href="http://xlmmap.yunchiwangluo.com?street=文教&lng=121.549978&lat=29.904661" /> <!-- 前江街道 -->
						<area shape="circle" coords="603,402,50" href="http://xlmmap.yunchiwangluo.com?street=外滩&lng=121.568295&lat=29.888622" /> <!-- 前江街道 -->
						<area shape="circle" coords="752,343,50" href="http://xlmmap.yunchiwangluo.com?street=孔浦&lng=121.60211&lat=29.909491" /> <!-- 前江街道 -->
					</map>

				</div>

				<div class="left">
					<dv-border-box-11 class="border" :title="'燃气企业总点数：67897点'">
						<div class="content1">
							<div style="margin-top: 10px;">
								<div style="color:#FFF">液化石油气企业：5678点</div>
								<div class="chart">
									<div class="left">
										<dv-active-ring-chart :config="chartConfig5" style="width:100px;height:100px" />
									</div>
									<div class="right">
										<div class="item">储配站：738点</div>
										<div class="item">供应站：532点</div>
										<div class="item">气化站：123点</div>
										<div class="item">加气站：422点</div>
									</div>
								</div>
							</div>

							<div style="margin-top: 10px;">
								<div style="color:#FFF">天然气企业：678点</div>
								<div class="chart">
									<div class="left">
										<dv-active-ring-chart :config="chartConfig6" style="width:100px;height:100px" />
									</div>
									<div class="right">
										<div class="item">压缩加气母站：738点</div>
										<div class="item">压缩储备站：532点</div>
									</div>
								</div>
							</div>
						</div>
					</dv-border-box-11>
					<dv-border-box-11 class="border" :title="'截止当今已检查：67897点'">
						<div class="content1">
							<div style="margin-top: 10px;">
								<div style="color:#FFF">液化石油气企业：5678点</div>
								<div class="chart">
									<div class="left">
										<dv-active-ring-chart :config="chartConfig7" style="width:100px;height:100px" />
									</div>
									<div class="right">
										<div class="item">储配站：738点</div>
										<div class="item">供应站：532点</div>
										<div class="item">气化站：123点</div>
										<div class="item">加气站：422点</div>
									</div>
								</div>
							</div>

							<div style="margin-top: 10px;">
								<div style="color:#FFF">天然气企业：678点</div>
								<div class="chart">
									<div class="left">
										<dv-active-ring-chart :config="chartConfig8" style="width:100px;height:100px" />
									</div>
									<div class="right">
										<div class="item">压缩加气母站：738点</div>
										<div class="item">压缩储备站：532点</div>
									</div>
								</div>
							</div>
						</div>
					</dv-border-box-11>
				</div>


			</div>

			<div class="bottom">

				<div class="item" v-for="(v,index) in plateformData.streetStatisticList" :key="index">
					<dv-border-box-1>
						<div class="title-border">

							<dv-decoration-6 style="width: 100px;height:15px;margin-right: 10px;" />
							<div>{{v.streetName}}</div>
							<dv-decoration-6 style="width: 100px;height:15px;margin-left: 10px;" />
						</div>
						<div class="info">
							<div class="text-item">
								<div class="danger">{{v.hightNum}}</div>
								<div class="text">高风险</div>
							</div>
							<div class="text-item">
								<div class="warm">{{v.middleNum}}</div>
								<div class="text">一般风险</div>
							</div>
							<div class="text-item">
								<div class="success">{{v.lowNum}}</div>
								<div class="text">低风险或无风险</div>
							</div>
						</div>
					</dv-border-box-1>
				</div>


			</div>

		</dv-full-screen-container>

	</div>
</template>

<script>
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		created() {
			var _this = this;
			this.getData()

			this.getTime();
			setInterval(function() {
				_this.getTime();
			}, 1000)

		},
		methods: {
			getMousePosition(e)
			{
				this.x = e.x //获取鼠标的X坐标（鼠标与屏幕左侧的距离，单位为px）
				this.y = e.y //获取鼠标的Y坐标（鼠标与屏幕顶部的距离，单位为px）
				
				window.location.href = "http://localhost:8081/?a="+(this.x - 500)+','+(this.y - 200)
				
			},
			getData() {
				var _this = this;
				let xhr = new XMLHttpRequest()

				xhr.onloadend = function() {
					// 接收请求后端返回的数据
					let result = xhr.responseText
					// console.log(result);
					// 由于大部分接口返回的数据都是JSON格式，所以需要转化
					const data = JSON.parse(result)
					_this.plateformData = data.data

					// _this.chartConfig1.data =[ { name:'居民',value:100},{ name:'非居民',value:200} ]


					_this.chartConfig1.data = [{
						name: '居民',
						value: _this.plateformData.bottledResidentNum
					}, {
						name: '非居民',
						value: _this.plateformData.bottledNonResidentNum
					}]
					_this.chartConfig2.data = [{
						name: '居民',
						value: _this.plateformData.conduitResidentNum
					}, {
						name: '非居民',
						value: _this.plateformData.conduitResidentNum
					}]
					_this.chartConfig3.data = [{
						name: '居民',
						value: _this.plateformData.checkedBottledResidentNum
					}, {
						name: '非居民',
						value: _this.plateformData.checkedBottledNonResidentNum
					}]
					_this.chartConfig4.data = [{
						name: '居民',
						value: _this.plateformData.checkedConduitResidentNum
					}, {
						name: '非居民',
						value: _this.plateformData.checkedConduitNonResidentNum
					}]
					// 将数据渲染到页面
					_this.$forceUpdate()
				}
				xhr.open("post", 'http://47.97.213.141:8080/cat/api/screen/screenStatistic')
				xhr.send(null)
			},
			getTime() {
				var vWeek = "";
				var vWeek_s = "";
				var vDay = "";
				vWeek = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
				var date = new Date();
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
				var hours = date.getHours();
				var minutes = date.getMinutes();
				var seconds = date.getSeconds();
				vWeek_s = date.getDay();
				this.nowTime = year + "年" + month + "月" + day + "日" + "\t" + hours + ":" + minutes + ":" + seconds + "\t" +
					vWeek[vWeek_s];
			},
			toManager() {
				window.location.href = "http://47.97.213.141:8080/cat/index"
			}
		},
		data() {
			return {
				nowTime: "",
				x:0,
				y:0,
				area: require('@/assets/images/area.png'),
				plateformData: {},
				chartConfig1: {
					radius: 30,
					activeRadius: 40,
					showOriginValue: true,
					digitalFlopStyle: {
						fontSize: 15,
						fill: '#fff'
					},
					lineWidth: 10,
					data: ""
				},
				chartConfig2: {
					radius: 30,
					activeRadius: 40,
					showOriginValue: true,
					digitalFlopStyle: {
						fontSize: 15,
						fill: '#fff'
					},
					lineWidth: 10,
					data: ""
				},
				chartConfig3: {
					radius: 30,
					activeRadius: 40,
					showOriginValue: true,
					digitalFlopStyle: {
						fontSize: 15,
						fill: '#fff'
					},
					lineWidth: 10,
					data: []
				},
				chartConfig4: {
					radius: 30,
					activeRadius: 40,
					showOriginValue: true,
					digitalFlopStyle: {
						fontSize: 15,
						fill: '#fff'
					},
					lineWidth: 10,
					data: []
				},
				chartConfig5: {
					radius: 30,
					activeRadius: 40,
					showOriginValue: true,
					digitalFlopStyle: {
						fontSize: 15,
						fill: '#fff'
					},
					lineWidth: 10,
					data: [{
							name: "储配站",
							value: 738
						},
						{
							name: "供应站",
							value: 532
						},
						{
							name: "气化站点",
							value: 123
						},
						{
							name: "加气站",
							value: 422
						}
					]
				},
				chartConfig6: {
					radius: 30,
					activeRadius: 40,
					showOriginValue: true,
					digitalFlopStyle: {
						fontSize: 15,
						fill: '#fff'
					},
					lineWidth: 10,
					data: [{
							name: "压缩加气母站",
							value: 738
						},
						{
							name: "压缩储备站",
							value: 532
						}
					]
				},
				chartConfig7: {
					radius: 30,
					activeRadius: 40,
					showOriginValue: true,
					digitalFlopStyle: {
						fontSize: 15,
						fill: '#fff'
					},
					lineWidth: 10,
					data: [{
							name: "储配站",
							value: 738
						},
						{
							name: "供应站",
							value: 532
						},
						{
							name: "气化站点",
							value: 123
						},
						{
							name: "加气站",
							value: 422
						}
					]
				},
				chartConfig8: {
					radius: 30,
					activeRadius: 40,
					showOriginValue: true,
					digitalFlopStyle: {
						fontSize: 15,
						fill: '#fff'
					},
					lineWidth: 10,
					data: [{
							name: "压缩加气母站",
							value: 738
						},
						{
							name: "压缩储备站",
							value: 532
						}
					]
				},

			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	body {
		padding: 0;
		margin: 0;
		color: #FFFFFF;
	}

	.content {
		background: url("@/assets/images/bg.png") no-repeat;
		background-size: 100% 100%;

		/* display: flex;
  flex-direction: columns; */

	}

	.top {
		background: url("@/assets/images/top.png") no-repeat;
		background-size: 100% 100%;
		height: 30px;
		display: flex;
		justify-content: space-between;
		padding: 30px;
		padding-bottom: 50px;
	}

	.top .left {
		font-size: 20px;
		font-family: Alibaba;
		font-weight: normal;
		color: #FFFFFF;
		flex: 1;
		text-align: left;
		text-shadow: 0px 1px 1px rgba(11, 24, 75, 0.5000);
		margin-bottom: 10px;
	}

	.top .center {
		font-size: 30px;
		font-family: Alibaba;
		font-weight: normal;
		color: #FFFFFF;
		font-weight: bold;
		flex: 1;
		text-align: center;
		text-shadow: 0px 1px 1px rgba(11, 24, 75, 0.5000);
	}

	.top .right {
		font-size: 20px;
		font-family: Alibaba;
		font-weight: normal;
		color: #FFFFFF;
		flex: 1;
		text-align: right;
		text-shadow: 0px 1px 1px rgba(11, 24, 75, 0.5000);
		margin-bottom: 10px;
	}

	.info {
		color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		padding: 0 30px;
	}

	.info .row {
		flex: 1
	}

	.info .row .border {
		padding: 10px;
		font-size: 20px;
		height: auto;
	}

	.info .bottom {
		font-size: 20px;
		margin-top: 20px;
	}

	.info .center {
		font-size: 30px;
		font-family: PangMenZhengDao;
		font-weight: normal;
		color: #FDFDFD;
		flex: 2;
	}

	.info .center .title {
		font-size: 30px;
		font-family: PangMenZhengDao;
		font-weight: normal;
		color: #FDFDFD;
	}

	.info .center .day {
		font-size: 30px;
		font-family: PangMenZhengDao;
		font-weight: normal;
		color: #FDFDFD;
	}

	.body {
		padding: 30px;
		padding-top: 0;
		display: flex;

	}

	.body .left {
		flex: 1;

	}

	.body .left .border {
		height: auto;
		padding: 20px;
		padding-top: 50px;
		display: flex;
		justify-content: space-between;
	}

	.body .left .border .content2 {}

	.body .left .border .content1 {
		display: flex;
		justify-content: space-around;
	}

	.body .center {
		flex: 2;
		padding: 0 50px;

	}

	.body .center .area {
		width: 100%;
		height: 50vh;
	}

	.body .right {
		flex: 1;
	}


	.chart {
		display: flex;
		align-items: center;

	}


	.chart .left {
		flex: 1;
	}

	.chart .right {
		display: flex;
		flex-direction: column;
		justify-content: space-around;

	}

	.chart .right .item {
		color: #FFF;
		font-size: 12px;
		padding: 5px;
	}

	.bottom {
		display: flex;
		padding: 0 30px;
		flex-wrap: wrap;
	}

	.bottom .item {
		width: 25%;
		margin-top: 10px;
	}

	.bottom .item .title-border {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-family: Alibaba;
		font-weight: normal;
		color: #FFFFFF;
	}

	.bottom .item .info {
		display: flex;
		justify-content: space-between;
	}

	.bottom .item .info .text-item {
		padding-top: 11px;
		padding-bottom: 17px;
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	.danger {
		font-size: 18px;
		font-family: Alibaba;
		font-weight: normal;
		color: #FC3F3F;
	}

	.warm {
		font-size: 18px;
		font-family: Alibaba;
		font-weight: normal;
		color: #FCD63F;
	}

	.success {
		font-size: 18px;
		font-family: Alibaba;
		font-weight: normal;
		color: #05D78C;
	}

	.text {
		font-size: 14px;
		font-family: Alibaba;
		font-weight: normal;
		color: #DFECF6;
	}
</style>
